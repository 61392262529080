.graph {
  text-align: center;
  padding: 2rem 1rem 1rem;
}
.graph_container {
  position: relative;
  margin: 0 auto 1.5rem;
  max-width: 10rem;
}
.graph_data {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.donut {
  width: auto !important;
  height: auto !important;
  overflow: visible;
}
