@import './variables';

$prefixClass: rc-slider;

$handle-color: $background-gray;
$disabledColor: #00f;
$border-radius-base: 6px;
$primary-color: $dark-text;
$tooltip-color: $dark-text;
$tooltip-bg: $handle-color;
$tooltip-arrow-width: 0.5rem;
$tooltip-distance: $tooltip-arrow-width;
$tooltip-arrow-color: $tooltip-bg;
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);

@mixin borderBox() {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); //  remove tap highlight color for mobile safari

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(
      0,
      0,
      0,
      0
    ); //  remove tap highlight color for mobile safari
  }
}

.#{$prefixClass} {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: $border-radius-base;
  touch-action: none;
  @include borderBox();

  &-rail {
    position: absolute;
    width: 100%;
    background-color: $dark-text;
    height: 0.5em;
    border-radius: $border-radius-base;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 0.5em;
    border-radius: $border-radius-base;
    background-color: lighten($primary-color, 60%);
  }

  &-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -0.5rem;
    width: 1rem;
    height: 1.5rem;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 20%;
    border: solid 1px darken($handle-color, 10%);
    background-color: $handle-color;
    touch-action: pan-x;

    &:focus {
      border-color: lighten($primary-color, 20%);
      box-shadow: 0 0 0 5px lighten($primary-color, 50%);
      outline: none;
    }

    &-click-focused:focus {
      border-color: lighten($primary-color, 50%);
      box-shadow: unset;
    }

    &:hover {
      border-color: lighten($primary-color, 20%);
    }

    &:active {
      border-color: lighten($primary-color, 20%);
      box-shadow: 0 0 5px lighten($primary-color, 20%);
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -0.25rem;
    margin-left: -0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    border: 2px solid lighten($dark-text, 50%);
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    &-active {
      border-color: lighten($primary-color, 50%);
      width: auto;
      height: 0.5rem;
      border-radius: 0;
      left: 0;
      background: $tooltip-bg;
      right: 0;
      bottom: -0.25rem;
    }
  }

  &-disabled {
    background-color: #e9e9e9;

    .#{$prefixClass}-track {
      background-color: $disabledColor;
    }

    .#{$prefixClass}-handle,
    .#{$prefixClass}-dot {
      border-color: $disabledColor;
      box-shadow: none;
      background-color: #fff;
      cursor: not-allowed;
    }

    .#{$prefixClass}-mark-text,
    .#{$prefixClass}-dot {
      cursor: not-allowed !important;
    }
  }
}

.#{$prefixClass}-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;

  .#{$prefixClass} {
    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    &-handle {
      margin-left: -5px;
      margin-bottom: -7px;
      touch-action: pan-y;
    }

    &-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;
      margin-bottom: -4px;
      &:first-child {
        margin-bottom: -4px;
      }
      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

@mixin motion-common() {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
}

@mixin make-motion($className, $keyframeName) {
  .#{$className}-enter,
  .#{$className}-appear {
    @include motion-common();
    animation-play-state: paused;
  }
  .#{$className}-leave {
    @include motion-common();
    animation-play-state: paused;
  }
  .#{$className}-enter.#{$className}-enter-active,
  .#{$className}-appear.#{$className}-appear-active {
    animation-name: '#{$keyframeName}In';
    animation-play-state: running;
  }
  .#{$className}-leave.#{$className}-leave-active {
    animation-name: '#{$keyframeName}Out';
    animation-play-state: running;
  }
}
@mixin zoom-motion($className, $keyframeName) {
  @include make-motion($className, $keyframeName);
  .#{$className}-enter,
  .#{$className}-appear {
    transform: scale(0, 0); // need this by yiminghe
    animation-timing-function: $ease-out-quint;
  }
  .#{$className}-leave {
    animation-timing-function: $ease-in-quint;
  }
}
@include zoom-motion(rc-slider-tooltip-zoom-down, rcSliderTooltipZoomDown);

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.#{$prefixClass}-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;

  @include borderBox();

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: $tooltip-arrow-width 0 $tooltip-distance 0;
  }

  &-inner {
    padding: 0 0.5rem;
    min-width: 2rem;
    height: 1.5rem;
    font-size: $font-size-sm;
    font-weight: bold;
    line-height: 1.5;
    color: $tooltip-color;
    text-align: center;
    text-decoration: none;
    background-color: $tooltip-bg;
    border-radius: 0;
    box-shadow: 0 0 4px #d9d9d9;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow {
    bottom: $tooltip-distance - $tooltip-arrow-width;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
}

.react-tooltip {
  font-size: $font-size-sm;
  color: $dark-text !important;
  text-transform: none;
  background-color: $tooltip-bg !important;
  border-radius: $border-radius-base !important;
  box-shadow: 0 0 4px #d9d9d9;
  &.place-top {
    &:after {
      margin-left: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0 !important;
      border-top-color: $tooltip-arrow-color !important;
      border-top-style: solid !important;
    }
  }
}

.react-tooltip p {
  vertical-align: middle;
  text-align: center;
  margin: 2px;
}
