@import '../../styles/variables';
@import '../../styles/layout';
@import '../../styles/responsiveness';

.wrapper {
  @extend %wrapper;
}

.hero {
  grid-column: 1/3;
  background-color: $background-gray;
  text-align: center;
  padding: 2em;

  > div {
    margin: 0 auto;
    max-width: 40em;
  }
}
.page {
  grid-column: 1/3;
  width: 100%;
  max-width: $external-size;
  margin: 0 auto;
  background: $background-gray;
}
.page_inner {
  max-width: $internal-size;
  margin: 0 auto;
}

.footer {
  grid-column: 1/3;
}
.columns {
  display: flex;
  margin: 0 -1em;
}
.column,
.explore {
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.column {
  flex: 1 1 auto;
  flex-basis: 33%;
  margin: 0 1em;
  text-align: center;
  padding: 1.5em 0 0;
}
.graphs {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  font-size: $font-size-sm;
  margin: 0 1em;

  :global(.graph) {
    padding-top: 0.5em;
    padding-bottom: 0;
  }
}
.explore {
  padding: 1.5em;
  margin: 1em 0 2em;

  h3 {
    text-transform: uppercase;
    margin-top: 0;
  }
}
.actions {
  text-align: center;
}
.explore_button {
  @extend %button;

  color: $light-text;
  background: $dark-text;

  &:hover {
    color: $dark-text;
    background: $light-text;
    text-decoration: none;
  }
}
.parameters {
  dl {
    display: grid;
    margin: 0 1em 2em;
    grid-template-columns: auto auto;
    grid-gap: 0.5em;
    justify-content: center;
    font-size: $font-size-sm;
  }
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dd {
    text-align: left;
    text-transform: uppercase;
  }
  dt {
    font-weight: bold;
    text-align: right;
  }
}
