@import-normalize; /* bring in normalize.css styles */
@import './styles/variables';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web');

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Titillium Web', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: grid;
  font-size: 16px;
  color: $dark-text;
}

#root {
  overflow-y: hidden;
  display: grid;
  width: 100%;
  max-width: $external-size;
  margin: 0 auto;
}

h2 {
  text-transform: uppercase;
  font-size: $font-size-h1;
}

a {
  color: inherit;
}

.svg-inline--fa.fa-w-16 {
  margin-top: 1px;
}
