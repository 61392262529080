@import '../styles/variables';
@import '../styles/responsiveness';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  height: 100vh;
}

.left,
.right {
  display: flex;
  flex-direction: column;
}
.left {
  background-image: url("../images/home-left.jpg");
  background-position: center;
  color: $light-text;
}
.right {
  background-image: url("../images/home-right.jpg");
  background-position: center;
  color: $gray-text;
}

.section {
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  justify-content: space-around;
  padding: 0 3em;

  > div {
    margin: 4em auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.callout {
  font-size: $font-size-lg;
  margin-top: 0;
}

.button_light {
  @extend %button;
  color: $light-text;
  border-color: $light-text;
}
.button_dark {
  @extend %button;
  color: $gray-text;
  border-color: $gray-text;
}

.footer {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0 1.5em;
  text-align: right;
}
