@import '../../styles/variables';

.menu {
  border-bottom: 1px solid $dark-text;
  ul {
    text-align: right;
    list-style: none;
    margin: 0 3em 0 0;
    padding: 0;
  }
  li {
    display: inline;
  }
  a {
    position: relative;
    bottom: -1px;
    display: inline-block;
    padding: 1.15em 1em 1.15em;
    color: $dark-text;
    text-decoration: none;
  }
  .active {
    border-bottom: 1px solid $dark-text;
    font-weight: bold;
  }
}
