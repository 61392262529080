@import '../../styles/variables';
@import '../../styles/layout';
@import '../../styles/responsiveness';

.wrapper {
  @extend %wrapper;
  grid-template-rows: auto auto 1fr auto;
}

.page {
  grid-column: 1/3;
  width: 100%;
  max-width: $narrow-size;
  margin: 0 auto;

  h2 {
    font-size: $font-size-base;
    font-weight: bold;
    margin: 3rem 0 0.5rem;
  }
}

.footer {
  grid-column: 1/3;
}

.hero {
  grid-column: 1/3;
  background-color: #f2f3f6;
  text-align: center;
  padding: 2em;

  > div {
    margin: 0 auto;
    max-width: $narrow-size;
  }
}

.actions {
  text-align: center;
}

.selectors {
  display: flex;
  margin: 3em -1em 0;
  > div {
    margin: 1em;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column-reverse;
  }
  .select_subject {
    margin-top: 1em;
    display: flex;
    justify-content: space-around;
  }
}

.range {
  background: #ccc;
  position: relative;
  height: 0.5em;

  .range_active {
    background: $dark-text;
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 5px;
  }

  .range_marker_left,
  .range_marker_right {
    position: absolute;
    font-size: 0.75rem;
    top: 150%;
    background: #eee;
    color: $gray-text;
    padding: 0.2em 0.5em;

    &:before {
      content: ' ';
      background: #eee;
      position: absolute;
      top: -5px;
      height: 5px;
      width: 5px;
    }
  }
  .range_marker_left {
    transform: translate(-100%);
    &:before {
      right: 0;
    }
  }
  .range_marker_right {
    transform: translate(100%);
    &:before {
      left: 0;
    }
  }
}

.outcomes {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  grid-row-gap: 1.5em;
  grid-column-gap: 3em;
}

label {
  display: flex;
  align-items: center;
}
