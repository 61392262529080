%wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto 1fr auto;
  min-height: 100vh;

  h1 {
    font-size: $font-size-h3;
    color: $dark-text;
    text-transform: uppercase;
  }
}
