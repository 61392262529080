$font-size-h1: 40px;
$font-size-h2: 32px;
$font-size-h3: 25px;
$font-size-lg: 20px;
$font-size-base: 16px;
$font-size-sm: 14px;
$font-size-tiny: 12px;

$gray-text: #666;
$dark-text: #466587;
$light-text: #fff;
$dark-background: #58728c;
$light-background: #eee;
$muted-text: #ccc;
$background-gray: #f2f3f6;

$external-size: 1440px;
$internal-size: 1140px;
$narrow-size: 750px;

%button {
  margin-top: 1em;
  display: inline-block;
  border: 1px solid;
  border-radius: 3em;
  padding: 1em;
  max-width: 14em;
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-size: $font-size-sm;
  &:hover {
    text-decoration: underline;
  }
}
