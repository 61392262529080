@import '../../styles/variables';

.header {
  border-bottom: 1px solid;
  text-align: center;
  text-transform: uppercase;
  padding: 1.42em 1em;
  font-size: $font-size-sm;
  font-weight: bold;

  p {
    margin: 0;
  }
}

.dark {
  border-color: $light-text;
}
.light {
  border-color: $dark-text;
}
