@import '../../styles/variables';
@import '../../styles/responsiveness';

.wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100vh;

  h1 {
    font-size: $font-size-h3;
    color: $dark-text;
    text-transform: uppercase;
  }
}

.page {
  grid-column: 1/3;
  width: 100%;
  max-width: $narrow-size;
  margin: 0 auto;

  h2 {
    font-size: $font-size-base;
    font-weight: bold;
    margin: 3rem 0 0.5rem;
  }
}

.simulation_parameters {
  margin: 2em 0;
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-row-gap: 3.5em;
  grid-column-gap: 0em;
  border: 1px solid #ccc;
  padding: 5em 7em;
  text-transform: uppercase;
  border-radius: 0.5em;
}

label {
  padding-left: 0.5em;
  padding-right: 3em;
}

.footer {
  grid-column: 1/3;
}

.hero {
  grid-column: 1/3;
  background-color: #f2f3f6;
  text-align: center;
  padding: 2em;

  > div {
    margin: 0 auto;
    max-width: $narrow-size;
  }
}

.actions {
  text-align: center;
}

.simulate {
  @extend %button;

  color: $light-text;
  background: $dark-text;

  &:hover {
    color: $dark-text;
    background: $light-text;
    text-decoration: none;
  }
}
.simulate_disabled {
  background: lighten($dark-text, 30%);
  pointer-events: none;
}
