@import '../../styles/variables';
@import '../../styles/layout';
@import '../../styles/responsiveness';

.wrapper {
  @extend %wrapper;
}

.hero {
  grid-column: 1/3;
}
.page {
  grid-column: 1/3;
  width: 100%;
  max-width: $external-size;
  margin: 0 auto;
  background: $background-gray;
}
.page_inner {
  max-width: $internal-size;
  margin: 0 auto;
}

.footer {
  grid-column: 1/3;
}

.hero {
  background-color: $background-gray;
  text-align: center;
  padding: 2em;

  > div {
    margin: 0 auto;
    max-width: 40em;
  }
}

.graphs,
.error {
  display: flex;
  justify-content: space-around;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  margin: 1em 0;
}

.parameters {
  grid-column: 1/3;
  background: $background-gray;
  max-width: $external-size;
  margin: 0 auto;
  > div {
    max-width: $internal-size;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    > div {
      width: 50%;
      display: flex;
      white-space: nowrap;
      max-width: 30em;
      > div {
        margin-right: 2em;
      }
    }
  }
  width: 100%;
}

.error {
  min-height: 25vh;
  align-items: center;
  color: lighten($gray-text, 30%);
  font-size: $font-size-lg;
}

.graphs {
  :global(.graph) {
    width: 25%;
  }
}
